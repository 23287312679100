import { Backdrop, Fade, Modal } from "@material-ui/core"
import React from "react"

interface IProps {
	open: boolean
	handleClose: () => void
	title: React.ReactNode
	content: React.ReactNode
}

export default function ErrorModal(props: IProps) {
	const { open, handleClose, title, content } = props

	return (
		<Modal
			key={`error-modal`}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className="error-modal">
					<div className="main">
						<div className="row">
							{title && <div className="col-12 title">{title}</div>}
							<div className="col-12 content">{content}</div>
							<div className="col-12 d-flex justify-content-center">
								<button className="btn-close" onClick={handleClose}>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}
