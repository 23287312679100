import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Giphy from "../../../../assets/images/form-design/giphy-submit-waiting.gif";
import LegaleristeWelcome from "../../../../assets/images/form-design/legaleriste-info-welcome.png";
import { WEBSITE_URL } from "../../../../constants/url-config";

interface IProps {
  hidden: boolean;
  step: 0 | 1;
}

export default function Designs(props: IProps) {
  const { hidden, step } = props;
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return !hidden ? (
    <div className="step step--designs">
      {step ? (
        <>
          <p className="step__name">{t("formRequestNewDesign.step")} 5</p>
          <h1 className="step__title">
            You're done! <br /> Check you inbox :)
          </h1>
          <div className="line">
            <div></div>
          </div>
          <div className="content">
            <div>
              <div>
                <p>
                  Our Montreal-based staff is working on your designs! This
                  process can take up to two business days as designs are made
                  by living-breathing designers here in Montreal.
                </p>
                <p>Let's create beauty together!</p>
                <button
                  className="btn-ok"
                  onClick={() => {
                    window.location.href = WEBSITE_URL;
                  }}
                >
                  OK!
                </button>
              </div>
            </div>

            <img
              src={LegaleristeWelcome}
              alt="LegaleristeWelcome"
              className="img-welcome"
            />
          </div>
        </>
      ) : (
        <>
          <p className="step__name">{t("formRequestNewDesign.step")} 5</p>
          <h1
            className="step__title"
            dangerouslySetInnerHTML={{ __html: t("Donotclose") }}
          ></h1>

          <div className="line line--design">
            <div></div>
          </div>

          <div className="content">
            <img src={Giphy} alt="Giphy" />

            <div>
              <div>{t("Weareloading")}</div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
}
