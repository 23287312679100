export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT || "";
export const ARTWORK_BASE_URL =
  process.env.REACT_APP_API_ARTWORK_ENDPOINT || "";

export const WEBSITE_URL = "https://www.legaleriste.com/en/";

export const WEBSITE_CONFIRMATION = "https://legaleriste.info/confirmation";
export const WEBSITE_CONFIRMATION1 = "https://legaleriste.info/confirmation-1";

export enum ROUTE_PATHS {
  "Home" = "/",
}
