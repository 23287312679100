import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  header: {
    backgroundColor: '#e6e6e6',
    height: '18px'
  },
  cell: {
    fontSize: '12px',
    fontFamily: 'CircularStd-Bold',
    padding: '6px'
  }
})