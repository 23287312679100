import React from "react"
import Dropzone from "react-dropzone"

type Props = {
	id?: string
	acceptedFiles?: (files: File[]) => void
	rejectedFiles?: (files: File[]) => void
	accept?: string
	multiple?: boolean
	hidden?: boolean
	children?: React.ReactNode
	className?: string
}

// for profile picture
export default function Upload(props: Props) {
	const { id, accept, multiple, hidden, children, className } = props

	const onDrop = (accepted: File[], rejected: File[]) => {
		if (props.acceptedFiles) props.acceptedFiles(accepted)
		if (props.rejectedFiles) props.rejectedFiles(rejected)
	}

	return (
		<Dropzone
			id={id}
			hidden={hidden}
			multiple={multiple || false}
			accept={accept}
			tabIndex={-1}
			role="application"
			aria-hidden="true"
			className={className}
			onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
		>
			{({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
				// for drag and drop warning statement
				if (isDragReject) return "Please submit a valid file"
				return <div>{children}</div>
			}}
		</Dropzone>
	)
}
