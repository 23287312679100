import React from "react"

type Props = {
	handleDrop: any
	class?: string
}

type States = {
	dragging: boolean
	drag: boolean
}

class DragAndDrop extends React.Component<Props, States> {
	private dropRef: React.RefObject<HTMLInputElement>

	constructor(props: Props) {
		super(props)

		this.state = {
			dragging: false,
			drag: false,
		}

		this.dropRef = React.createRef()
	}

	dragCounter = 0

	handleDrag = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
	}

	handleDragIn = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		this.dragCounter++
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ dragging: true })
		}
	}

	handleDragOut = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		this.dragCounter--
		if (this.dragCounter > 0) return
		this.setState({ dragging: false })
	}

	handleDrop = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		this.setState({ drag: false })
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files)
			e.dataTransfer.clearData()
			this.dragCounter = 0
		}
	}

	componentDidMount = () => {
		this.dragCounter = 0
		let div = this.dropRef.current
		div!.addEventListener("dragenter", this.handleDragIn)
		div!.addEventListener("dragleave", this.handleDragOut)
		div!.addEventListener("dragover", this.handleDrag)
		div!.addEventListener("drop", this.handleDrop)
	}

	componentWillUnmount = () => {
		let div = this.dropRef.current
		div!.removeEventListener("dragenter", this.handleDragIn)
		div!.removeEventListener("dragleave", this.handleDragOut)
		div!.removeEventListener("dragover", this.handleDrag)
		div!.removeEventListener("drop", this.handleDrop)
	}

	render() {
		return (
			<div className={this.props.class} ref={this.dropRef}>
				{this.props.children}
			</div>
		)
	}
}

export default DragAndDrop
