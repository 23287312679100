import React, { useEffect, useState } from "react";
import { Box, Hidden } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from "react-i18next";
import { IDesignPackageRes } from "../../../../models/form-design/form-design-models";
import {
  getDesignPackage,
  getFAQ,
} from "../../../../services/from-design/from-design.service";
import { useStyles } from './styles'
import { AddBoxRounded } from '@material-ui/icons';

interface IProps {
  hidden: boolean;
  setShapes: (shapes: string[]) => void;
}

export default function Shape(props: IProps) {
  const { hidden, setShapes } = props;
  const { t } = useTranslation();
  const [designPackage, setDesignPackage] = useState<IDesignPackageRes[]>([]);
  const faq = getFAQ();
  

  useEffect(() => {
    setDesignPackage(getDesignPackage());
  }, []);

  return !hidden ? (
    <div className="step step--shape">
      <p className="step__name">{t("formRequestNewDesign.step")} 2</p>
      <h1
        className="step__title"
        dangerouslySetInnerHTML={{
          __html: t("nowPickTheFirstDesignsYouLikeToSee").replace(
            "{0}",
            "<br />"
          ),
        }}
      ></h1>

      <div className="line">
        <div></div>
      </div>

      <div className="row row--custom justify-content-center">
        {designPackage.map((item) => (
          <div
            className="col-lg-4 col-sm-4
						 col-6 col--custom"
            key={item.id}
            onClick={() => {
              setShapes(item.shapes);
            }}
          >
            <div className="card">
              <img className="card__img" src={item.avatarUrl} alt={item.name} />

              <div className="card__package-name">{t(item.name)}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="step__description">
        <div>
          <p>{t("faq.AtLeGaleriste")}</p>
          <p>{t("faq.Getstartedby")}</p>
          <p>{t("faq.Eitherwayyoull")}</p>
        </div>
      </div>

      <div className="line line--commitment">
        <div></div>
      </div>

      <div className="step__description">
        <div>
          <h1 className="step__title">FAQ</h1>

          <div className="mt-4">
            {faq.topFAQ.map((item, key) => (
              <div key={key}>
                <p className="text-font-bold">{t(item.title)}</p>
                <p>{t(item.description)}</p>
              </div>
            ))}
          </div>
          <PriceList />
          <div className="mt-4">
            {faq.bottomFAQ.map((item, key) => (
              <div key={key}>
                <p className="text-font-bold">{t(item.title)}</p>
                <p>{t(item.description)}</p>
              </div>
            ))}
          </div>

          <div
            className="back-to-top"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            {t("faq.BackToTop")}
          </div>
        </div>
      </div>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="line line--commitment">
          <div></div>
        </div>
      </Box>
    </div>
  ) : null;
}


function PriceList() {
  const { t } = useTranslation();
  const faq = getFAQ();
  const classes = useStyles();

  return (
    <img style={{ width: '100%' }} src='https://downloads.intercomcdn.com/i/o/629546058/9f302b7aac7b8af48db2792f/3.-Price-list-overview-en.jpg'/>
  )
}
