import React from "react";
import { Box, Hidden } from "@material-ui/core";
import LeGaleriste from "../../../assets/icons/Le_Galeriste_G_white.png";

interface IProps {
  t: any;
}

export default function Footer(props: IProps) {
  const { t } = props;

  return (
    <footer className="footer">
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <div className="footer__top">
          <div className="footer__top__left">
            <img src={LeGaleriste} alt="Le Galeriste" />
          </div>

          <div className="footer__top__right">
            <div className="">
              {t("footer.theOriginal")} <span>2010</span>
            </div>
            <div className="">{t("footer.humanlyAndEnvironmentally")}</div>

            <div className="mt-2">{t("footer.discounts")}</div>
            <div className="">{t("footer.artistsGetUpToOff")}</div>

            <div className="mt-2">{t("footer.royalties")}</div>
            <div className="">{t("footer.getOfOnlineSale")}</div>

            <div className="mt-2">{t("footer.neddHelp")}</div>
            <div className="">info@legaleriste.com</div>
          </div>
        </div>
        <div className="footer__bottom">
          <div>© 2020 Le Galeriste All rights reserved.</div>
          <div>300-8850 Av du Parc, Montréal, QC, H2N 1Y6</div>
        </div>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="desktop-content">
          <div className="footer__top footer__top--desktop">
            <div className="footer__top__left">
              <img src={LeGaleriste} alt="Le Galeriste" />
            </div>

            <div className="footer__top__center">
              <div>
                <div className="title">{t("footer.discounts")}</div>
                <div className="mt-3" dangerouslySetInnerHTML={{ __html: t("footer.artistsGetUpToOff") }} />
              </div>

              <div>
                <div className="title">{t("footer.royalties")}</div>
                <div className="mt-3" dangerouslySetInnerHTML={{ __html: t("footer.getOfOnlineSale") }} />
              </div>

              <div>
                <div className="title">{t("footer.neddHelp")}</div>
                <div className="mt-3">info@legaleriste.com</div>
              </div>
            </div>

            <div></div>
          </div>

          <div className="footer__bottom footer__bottom--desktop">
            <div>
              <div>
                {t("footer.theOriginal")} <span>2010</span>
              </div>
              <div>{t("footer.humanlyAndEnvironmentally")}</div>
            </div>
            <div>© 2023 Le Galeriste All rights reserved.<br/>
              300-8850 Av du Parc, Montréal, QC, H2N 1Y6
            </div>
          </div>
        </div>
      </Box>
    </footer>
  );
}
