import axios, { AxiosInstance, AxiosResponse, ResponseType } from "axios"
import qs from "qs"
import { API_BASE_URL } from "../constants/url-config"
import { authorizationHeader } from "./helper"

const axiosInstance = (
	handleErrorAutomatic: boolean,
	contentType: string = "application/json",
	responseType: ResponseType = "json",
	successMessage?: string
): AxiosInstance => {
	const instance = axios.create({
		baseURL: API_BASE_URL,
		headers: {
			"Content-Type": contentType,
		},
		responseType: responseType,
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			return Promise.reject(error)
		}
	)
	return instance
}

export const getAsync = (
	url: string,
	params?: { [key: string]: any },
	handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
	if (params?.hasOwnProperty("keyword")) {
		params.keyword = params.keyword.trim()
	}
	return axiosInstance(handleErrorAutomatic).get(url, {
		params: params,

		paramsSerializer: {
			encode: (params: any) => {
				return qs.stringify(params, { arrayFormat: "repeat" })
			}
		},
	})
}

export const postAsync = (
	url: string,
	data: object,
	contentType?: string,
	successMessage?: string,
	handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
	return axiosInstance(handleErrorAutomatic, contentType, undefined, successMessage).post(url, data)
}

export const putAsync = (
	url: string,
	json?: object,
	successMessage?: string,
	handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
	return axiosInstance(handleErrorAutomatic, undefined, undefined, successMessage).put(url, json)
}

export const deleteAsync = (
	url: string,
	successMessage?: string,
	handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
	return axiosInstance(handleErrorAutomatic, undefined, undefined, successMessage).delete(url)
}

export const downloadAsync = (url: string, params?: object): Promise<AxiosResponse> => {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_ENDPOINT,
		headers: Object.assign({}, authorizationHeader()),
		responseType: "blob",
	})
	return instance.get(url, { params: params })
}

export const postWithFormFileAsync = (url: string, file: File, folder: string) => {
	const formData = new FormData()
	formData.append(file.name, file)
	formData.append("folder", folder)

	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_ENDPOINT,
		headers: Object.assign({ "content-Type": "multipart/form-data" }, authorizationHeader()),
	})

	return instance.post(url, formData)
}

export const putWithFormFileAsync = (url: string, file: File) => {
	return axios.put(url, file, {
		headers: { "Content-Type": "application/octet-stream" },
	})
}
