import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { Route } from "react-router";
import { useTranslation, withTranslation } from "react-i18next";
import { ROUTE_PATHS } from "./constants/url-config";
import FromDesign from "./components/pages/form-design/form-design";
import Footer from "./components/shared/layout/footer";
import Header from "./components/shared/layout/header";
import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = 'maxhdrhe';

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    // Start of  google api script
    const googleApiHead = document.createElement("script");
    const googleApiBody = document.createElement("noscript");
    const intercom = document.createElement('script');

    if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
      //<!-- Google Tag Manager -->
      googleApiHead.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TX5D7N3');`;
      //<!-- End Google Tag Manager -->

      //<!-- Google Tag Manager (noscript) -->
      const iframe = document.createElement("iframe");
      iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-TX5D7N3";
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      googleApiBody.appendChild(iframe);
      //<!-- End Google Tag Manager (noscript) -->
    }

    // add to page
    document.head.appendChild(googleApiHead);
    document.body.appendChild(googleApiBody);

    return (): void => {
      document.head.removeChild(intercom);
      document.head.removeChild(googleApiHead);
      document.body.removeChild(googleApiBody);
    };
  }, []);

  const routes = [
    {
      href: ROUTE_PATHS.Home,
      exact: true,
      title: "home",
      component: FromDesign,
    },
  ];

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <div className="app container-fluid">
        <Header/>
        <main>
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.href}
                  exact={route.exact}
                  component={route.component}
                />
              ))}

              <Redirect from={ROUTE_PATHS.Home} to={ROUTE_PATHS.Home}/>
            </Switch>
          </BrowserRouter>
        </main>
        <Footer t={t}/>
      </div>
    </IntercomProvider>
  );
}

export default withTranslation()(App);
