export interface IDesignPackageRes {
  id: number;
  name: string;
  shapes: string[];
  avatarUrl: string;
}

export interface ITechniqueRes {
  id: string;
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export interface IStyleRes extends ITechniqueRes { }

export interface IArtworksInfos {
  name: string;
  technique: string;
  style: string;
}

export interface IArtistInfos {
  firstName: string;
  lastName: string;
  fullName: string; // Artist/Company Name
  email: string;
  country: string;
  countryId: string;
  state: string;
  stateId: string;
  city: string;
  cityId: string;
  artistNumber: string;
}

export const initArtistInfo: IArtistInfos = {
  firstName: "",
  lastName: "",
  fullName: "", // Artist/Company Name
  email: "",
  country: "",
  countryId: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  artistNumber: "",
};

export interface IFAQRes {
	topFAQ: {
		title: string
		description: string
	}[]
	bottomFAQ: {
		title: string
		description: string
	}[]
}

export interface IArtistPersona {
  id: number;
  name: string;
  value: string;
  avatarUrl: string;
}
