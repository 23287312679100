import { Box, Button, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { IArtistPersona } from "../../../../models/form-design/form-design-models";
import {
  getArtistHopesForWearableArt,
  getArtistMainOriginalArtOutlet,
  getArtistPersona,
} from "../../../../services/from-design/from-design.service";

const howDisArtistKnowOptions = [
  {
    value: 'Referral',
    text: 'artistStep.referredByAFriend'
  },
  {
    value: 'Saw product',
    text: 'artistStep.sawYourProductAndLovedIt'
  },
  {
    value: 'Facebook/Instagram',
    text: 'artistStep.facebook/Instagram'
  },
  {
    value: 'Pinterest',
    text: 'artistStep.pinterest'
  },
  {
    value: 'GoogleSearch',
    text: 'artistStep.googleSearch'
  },
  {
    value: 'TikTok',
    text: 'artistStep.tikTok'
  },
  {
    value: 'Snapchat',
    text: 'artistStep.snapchat'
  },
  {
    value: 'No clue',
    text: 'artistStep.iHaveNoClueHowIGotHere'
  }];
interface IProps {
  hidden: boolean;
  setArtistData: React.Dispatch<
    React.SetStateAction<{
      artistPersona: string;
      artistHopesForWearableArt: string;
      artistMainOriginalArtOutlet: string;
      howDidArtistKnow: string;
    }>
  >;
  handleSelectArtistData: (artistMainOriginalArtOutlet: string) => void;
}

const VIDEO_URL = 'https://player.vimeo.com/video/701912153';

export default function Artist(props: IProps) {
  const { hidden, setArtistData, handleSelectArtistData } = props;
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(0);
  const [artistPersona, setArtistPersona] = useState<IArtistPersona[]>([]);
  const [artistHopesForWearableArt, setArtistHopesForWearableArt] = useState<
    { id: number; name: string; value: string }[]
  >([]);
  const [artistMainOriginalArtOutlet, setArtistMainOriginalArtOutlet] =
    useState<{ id: number; name: string, value: string }[]>([]);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showVideoPopup, setShowVideoPopup] = useState<boolean>(false);
  const [selectedArtistPersonalChoice, setSelectedArtistPersonalChoice] = useState<string>('');

  useEffect(() => {
    setArtistPersona(getArtistPersona());
    setArtistMainOriginalArtOutlet(getArtistMainOriginalArtOutlet());
    setArtistHopesForWearableArt(getArtistHopesForWearableArt());
  }, []);

  
  const renderArtistMainOriginalArtOutlet = () => {
    return (
      <div className="list">
        {artistMainOriginalArtOutlet.map((item) => (
          <button
            key={item.id}
            onClick={() => {              
              setArtistData((pre) => {
                return {
                  ...pre,
                  artistMainOriginalArtOutlet: t(item.value),
                };
              });
              setActive(2);
            }}
            className="btn-deault btn btn-art-outlet"
          >
            {t(item.name)}
          </button>
        ))}
      </div>
    );
  };
  const renderArtistHopesForWearableArt = () => {
    return (
      <div className="list">
        {artistHopesForWearableArt.map((item) => (
          <button
            key={item.id}
            onClick={() => {
              setSelectedArtistPersonalChoice(t(item.value));
              setActive(3);
            }}
            className="btn-deault btn btn-art-outlet"
          >
            {t(item.name)}
          </button>
        ))}
      </div>
    );
  };

  const renderSection4 = () =>{
    return (
      <div className='list'>

        {howDisArtistKnowOptions.map((item) => (
          <button
            key={item.value}
            onClick={() => {
              setArtistData((pre) => {
                return {
                  ...pre,
                  howDidArtistKnow: item.value,
                };
              });
              setShowVideoPopup(true);
            }}
            className="btn-deault btn btn-art-outlet"
          >
            {t(item.text)}
          </button>
        ))}
      </div>
    )
  }

  const videoModal = () => {
    return (
      <>
        <div className='modal-backdrop fade show'>
        </div>
        <div className='modal fade show' style={{display: 'block', zIndex: 2000, overflowY: 'scroll'}}>
          <a className='close-overlay' onClick={() => {setShowVideoPopup(false); handleSelectArtistData(selectedArtistPersonalChoice);}}>X</a>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div>
                  {/* Chronium browser currently block autoplay if video not muted. Reference: https://www.theverge.com/2018/3/22/17150870/google-chrome-autoplay-videos-sound-mute-update */}
                  <ReactPlayer url={VIDEO_URL} muted={false} width='100%' height='534px' playing={videoPlaying} class='video-player'
                    onPause={() => {setVideoPlaying(false);}} onPlay={() => {setVideoPlaying(true); if (showOverlay){setShowOverlay(false)}}} onEnded={() => {setShowOverlay(true)}}
                    style={{backgroundColor: '#000'}}></ReactPlayer>

                  {showOverlay && <>
                    <div className='overlay'>
                      <div className='overlay-header'>Let’s Get Started!</div>
                      <div className='overlay-description'>{t("selectMyFirstPackage")}</div>
                      <Button className='btn-deault overlay-btn' onClick={() => {setShowVideoPopup(false); handleSelectArtistData(selectedArtistPersonalChoice);}}>Let’s go</Button>
                    </div>
                  </>}

                  <div className='skip-step'>
                    <a onClick={() => {setShowVideoPopup(false); handleSelectArtistData(selectedArtistPersonalChoice);}}>{t("selectMyFirstPackage")}</a>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </>
    )
  }

  return !hidden ? (
    <div className="step step--shape step--artist">
      <p className="step__name">{t("formRequestNewDesign.step")} 1</p>
      <h1
        className="step__title"
        dangerouslySetInnerHTML={{
          __html: t("firstTellUsABitaboutYourself"),
        }}
      ></h1>

      <div className="line">
        <div></div>
      </div>

      <div className="step__description">
        <div>
          <p>
            {`${active + 1} ${t("of")} 4 — `}
            {active === 0 &&
              t(
                "artistStep.whichOfTheseImagesBetterRepresentTheTypicalFanOfYourArt"
              )}
            {active === 1 &&
              t("artistStep.howDoYouMostConnectWithYourFansToSellYourArt")}
            {active === 2 &&
              t(
                "artistStep.whenYouEnvisionYourArtTransformedIntoFashionWhatAreYouHopingFor"
              )}
            {active === 3 &&
            t(
              "artistStep.howDidYouHearAboutUs"
            )}
          </p>
        </div>
      </div>

      <div className="row row--custom justify-content-center">
        {active === 0 &&
          artistPersona.map((item) => {
            return (
              <div
                className="col-lg-3 col-sm-3
						 col-6 col--custom"
                key={item.id}
                onClick={() => {
                  setArtistData((pre) => {
                    return {
                      ...pre,
                      artistPersona: t(item.value),
                    };
                  });
                  setActive(1);
                }}
              >
                <div className="card">
                  <img
                    className="card__img"
                    src={item.avatarUrl}
                    alt={item.name}
                  />

                  <div className="card__package-name">{t(item.name)}</div>
                </div>
              </div>
            );
          })}

        {active === 1 && renderArtistMainOriginalArtOutlet()}
        {active === 2 && renderArtistHopesForWearableArt()}
        {active === 3 && renderSection4()}
      </div>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="line line--commitment">
          <div></div>
        </div>
      </Box>

    {showVideoPopup && videoModal()}

    </div>
  ) : null;
}
