import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./languages/i18n";
import "./assets/css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// We had issues in Prod displaying it could not register, so removing for now until we figure what is happening.
/*serviceWorker.register({
  onUpdate: (sw) => {
    if (sw.waiting) {
      sw.waiting.postMessage({ type: "SKIP_WAITING" });

      window.location.reload(true);
      console.log("Update happened.");
    }
  },
});*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
