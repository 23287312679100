import axios, { AxiosInstance, AxiosResponse } from "axios"
import { IGeoData } from "../../models/geodata/geodata-models"

const axiosInstance = (): AxiosInstance => {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_ENDPOINT,
		headers: {
			"Content-Type": "application/json",
		},
		responseType: "json",
	})

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			return Promise.reject(error)
		}
	)
	return instance
}

export const getCountriesAsync = async (): Promise<AxiosResponse<IGeoData>> => {
	const countryURL = "/manage-design/countries"

	return axiosInstance().get(countryURL)
}

export const getStatesAsync = async (countryId: string): Promise<AxiosResponse<IGeoData>> => {
	const stateURL = `/manage-design/states?countryId=${countryId}`

	return axiosInstance().get(stateURL)
}

export const getCitiesAsync = async (countryId: string, stateId: string): Promise<AxiosResponse<IGeoData>> => {
	const cityURL = `/manage-design/cities?countryId=${countryId}&stateId=${stateId}`

	return axiosInstance().get(cityURL)
}
