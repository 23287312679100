import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  IArtistInfos,
  initArtistInfo,
} from "../../../../models/form-design/form-design-models";
import { checkEmailRegisteredAsync } from "../../../../services/from-design/from-design.service";
import {
  getCitiesAsync,
  getCountriesAsync,
  getStatesAsync,
} from "../../../../services/geodata/geodata.service";

interface IProps {
  hidden: boolean;
  handleSetInfos: (info: IArtistInfos) => void;
}

export default function Info(props: IProps) {
  const { hidden, handleSetInfos } = props;
  const { t } = useTranslation();

  const [artistInfo, setArtistInfo] = useState<IArtistInfos>({
    ...initArtistInfo,
  });
  const [showErrEmail, setShowErrEmail] = useState<boolean>(false);
  const [hasShowInputCity, setHasShowInputCity] = useState<boolean>(false);

  const [countries, setCountries] = useState<any>({} as any);
  const [states, setStates] = useState<any>({} as any);
  const [cities, setCities] = useState<any>({} as any);

  const confirmEmailEle = useRef<HTMLInputElement>(null);
  const [showErrConfirmEmail, setShowErrConfirmEmail] = useState<boolean>(false);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (artistInfo.countryId)
      getStatesAsync(artistInfo.countryId).then((res) => {
        setStates(res.data);
      });
  }, [artistInfo.countryId]);

  useEffect(() => {
    if (artistInfo.countryId && artistInfo.stateId)
      getCitiesAsync(artistInfo.countryId, artistInfo.stateId).then((res) => {
        setCities(res.data);
      });
  }, [artistInfo.countryId, artistInfo.stateId]);

  const getCountries = async () => {
    await getCountriesAsync().then((res) => {
      const data = setTopCountries(res.data);      
      setCountries(data);
    });
  };

  const setTopCountries = (data: any) => {
    let canada = data["CA"];
    let us = data["US"];

    delete data["CA"];
    delete data["US"];

    return {
      CA: canada,
      US: us,
      ...data,
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    
    if(confirmEmailEle.current?.value !== artistInfo.email) {
      handleCheckConfirmEmail(confirmEmailEle.current?.value || '', artistInfo.email, false );
      return
    };

    const res = await checkEmailRegistered(artistInfo.email);

    if (res.valueOf()) return;

    handleSetInfos(artistInfo);
  };

  const checkEmailRegistered = async (email: string) => {
    return await checkEmailRegisteredAsync(email)
      .then((res) => {
        setShowErrEmail(false);

        return false;
      })
      .catch(() => {
        setShowErrEmail(true);

        return true;
      });
  };

  const handleCheckConfirmEmail =  (confirmEmail: string, email: string, isInputConfirm: boolean) => {
    console.log("confirmEmailEle: ", confirmEmail);

    if(!confirmEmail && !isInputConfirm) setShowErrConfirmEmail(false)
    else if(email !== confirmEmail) setShowErrConfirmEmail(true)
    else setShowErrConfirmEmail(false)
  }

  return !hidden ? (
    <div className="step step--info">
      <p className="step__name">{t("formRequestNewDesign.step")} 4</p>
      <h1
        className="step__title"
        dangerouslySetInnerHTML={{ __html: t("Completeyourprofile") }}
      ></h1>

      <div className="line">
        <div></div>
      </div>

      <div className="content">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <label>{t("contactInformation")}:</label>
            </div>
            <div className="col-md-6">
              <input
                id="inputContactFirstName"
                type="text"
                placeholder={t("contactFirstName")}
                required
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return { ...pre, firstName: e.target.value };
                  });
                }}
              />
            </div>
            <div className="col-md-6">
              <input
                name="inputContactLastName"
                type="text"
                placeholder={t("contactLastName")}
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return { ...pre, lastName: e.target.value };
                  });
                }}
                required
              />
            </div>
            {/* email */}
            <div className="col-md-12">
              <input
                type="email"
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return { ...pre, email: e.target.value };
                  });
                }}
                placeholder={t("emailAddress")}
                onBlur={(e) => {
                  checkEmailRegistered(e.target.value);
                  handleCheckConfirmEmail(confirmEmailEle.current?.value || '', e.target.value, false );
                }}
                required
              />
              {showErrEmail && (
                <div
                  id="email-existed-error"
                  className="error"
                  dangerouslySetInnerHTML={{ __html: t("Youremailis") }}
                ></div>
              )}
            </div>
            {/* confirm email */}
            <div className="col-md-12">
              <input
                type="email"
                placeholder={t("confirmEmailAddress")}
                ref={confirmEmailEle}
                required
                onBlur={(e) => {
                  handleCheckConfirmEmail(e.target.value, artistInfo.email, true);
                }}
              />
              {showErrConfirmEmail && (
                <div
                  id="confirm-email-existed-error"
                  className="error"
                  dangerouslySetInnerHTML={{ __html: t("theEmailAddressesDoNotMatch") }}
                ></div>
              )}
            </div>
            <div className="col-md-12">
              <label className="artist-info-label">
                {t("artistInformation")}:
              </label>
            </div>
            <div className="col-md-6">
              <input
                name="inputCompanyName"
                type="text"
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return { ...pre, fullName: e.target.value };
                  });
                }}
                placeholder={t("artistcompanyName")}
                required
              />
            </div>
            <div className="col-md-6">
              <select
                name="country"
                className="countries"
                id="countryId"
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return {
                      ...pre,
                      countryId: e.target.value,
                      country: countries[e.target.value],
                    };
                  });
                }}
                required
              >
                <option value="">{t("Country")}</option>
                {Object.keys(countries).map((item) => {
                  return (
                    <option value={item} key={item}>
                      {countries[item]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6">
              <select
                name="state"
                className="states"
                id="stateId"
                onChange={(e) => {
                  setArtistInfo((pre) => {
                    return {
                      ...pre,
                      stateId: e.target.value,
                      state: states[e.target.value],
                    };
                  });

                  setHasShowInputCity(false);
                }}
                required
              >
                <option value="">{t("StateProvince")}</option>
                {Object.keys(states).map((item) => {
                  return (
                    <option value={item} key={item}>
                      {states[item]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6">
              {Object.keys(cities).length && !hasShowInputCity ? (
                <select
                  name="city"
                  className="cities"
                  id="cityId"
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      setHasShowInputCity(true);
                      return;
                    }

                    setArtistInfo((pre) => {
                      return {
                        ...pre,
                        cityId: e.target.value,
                        city: cities[e.target.value],
                      };
                    });
                  }}
                  required
                >
                  <option value="">{t("City")}</option>
                  <option value="0">{t("enterYourCity")}</option>
                  {Object.keys(cities).map((item) => {
                    return (
                      <option value={item} key={item}>
                        {cities[item]}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  name="city"
                  type="text"
                  id="cityId"
                  placeholder={t("City")}
                  autoFocus
                  value={artistInfo.city}
                  onChange={(e) => {
                    setArtistInfo((pre) => {
                      return {
                        ...pre,
                        cityId: e.target.value.replace(/ /g, ""),
                        city: e.target.value,
                      };
                    });
                  }}
                  required
                />
              )}
            </div>
            <div className="col-md-12">
              <button type="submit">{t("sendToDesign")}!</button>
            </div>
          </div>
        </form>
      </div>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="line">
          <div></div>
        </div>
      </Box>
    </div>
  ) : null;
}
