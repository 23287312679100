import React from "react"
import logo from "../../../assets/icons/logo_LG.svg"
import { WEBSITE_URL } from "../../../constants/url-config"
import { getLanguage } from "../../../helpers/storage"
import { changeLanguage } from "../../../languages/i18n"

export default function Header() {
	const language = getLanguage()

	return (
		<header id="headers">
			<div className="container p-0">
				<img
					src={logo}
					alt="Logo"
					className="logo"
					onClick={() => {
						window.location.href = WEBSITE_URL
					}}
				/>

				<button
				className="btn-translate"
				onClick={() => {
					changeLanguage(language === "en" ? "fr" : "en")
				}}
				>
					{language === "en" ? "FR" : "EN"}
				</button>
			</div>
		</header>
	)
}
