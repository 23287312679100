import { Add, Done } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Upload from "../../../shared/upload/upload";
import className from "classnames";
import {
  IArtworksInfos,
  IStyleRes,
  ITechniqueRes,
} from "../../../../models/form-design/form-design-models";
import {
  getStyles,
  getTechniques,
} from "../../../../services/from-design/from-design.service";
import { Box, Hidden } from "@material-ui/core";
import APlus from "../../../../assets/images/info-image/a-plus.png";
import Angle from "../../../../assets/images/info-image/angle.png";
import Blurry from "../../../../assets/images/info-image/blurry.png";
import Crossed from "../../../../assets/images/info-image/crossed.png";
import Flash from "../../../../assets/images/info-image/flash.png";
import NoContext from "../../../../assets/images/info-image/no-context.png";
import PictureFrame from "../../../../assets/images/info-image/picture-frame.png";
import DragAndDrop from "../../../shared/component/drag-and-drop/drag-and-drop";
import { useTranslation } from "react-i18next";

interface IProps {
  hidden: boolean;
  handleSetArtworksInfo: (
    artworksFiles: File[],
    artworksInfos: IArtworksInfos[]
  ) => void;
}

const initData = {
  name: "",
  technique: "",
  style: "",
};

export default function ART(props: IProps) {
  const { hidden, handleSetArtworksInfo } = props;
  const { t } = useTranslation();
  const [artworksFiles, setArtworksFiles] = useState<File[]>([]);
  const [artworksFilesReview, setArtworksFilesReview] = useState<string[]>([]);
  const [artworksInfos, setArtworksInfos] = useState<IArtworksInfos[]>([]);

  // current index select artwork
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [openErrModal, setOpenErrModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [firstChoosedArtwork, setFirstChoosedArtwork] = useState<boolean>(true);

  const onChangeFileUrl = (file: File) => {
    if (firstChoosedArtwork) setFirstChoosedArtwork(false);

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      let image = new Image();

      image.src = window.URL.createObjectURL(file);

      image.onload = function () {
        let imgWidth = image.naturalWidth;
        let imgHeight = image.naturalHeight;

        const m_pixels = Math.floor(
          (Number(imgWidth) * Number(imgHeight)) / 1000000
        );

        if (m_pixels < 6) {
          setOpen(false);
          setOpenErrModal(true);
        } else {
          handleSetArtworksFiles(file, image.src);
          setOpen(true);
          setOpenErrModal(false);

          document.getElementById("inputNameArtwork")?.focus();
        }
      };
    };
  };

  const handleSetArtworksFiles = (file: File, image: string) => {
    if (currentIndex === 0) {
      setArtworksFiles([file]);
      setArtworksFilesReview([image]);
    } else {
      if (artworksFiles.length === currentIndex + 1) {
        setArtworksFiles((pre) => {
          let newFiles = [...pre];

          newFiles[currentIndex] = file;

          return newFiles;
        });
        setArtworksFilesReview((pre) => {
          let newFiles = [...pre];

          newFiles[currentIndex] = image;

          return newFiles;
        });
      } else {
        setArtworksFiles((pre) => [...pre, file]);
        setArtworksFilesReview((pre) => [...pre, image]);
      }
    }
  };

  const handleAcceptFiles = (files: File[]) => {
    onChangeFileUrl(files[0]);
  };

  const handleUploadArtworks = (artworksInfo: IArtworksInfos) => {
    if (currentIndex < 2) {
      setCurrentIndex((pre) => pre + 1);
      setArtworksInfos((pre) => [...pre, artworksInfo]);
    } else
      handleSetArtworksInfo(artworksFiles, [...artworksInfos, artworksInfo]);

    setOpen(false);
  };

  const renderTitle = () => {
    if (currentIndex === 0)
      return (
        <h1
          className="step__title"
          dangerouslySetInnerHTML={{ __html: t("faq.Uploadupto3Artworks") }}
        ></h1>
      );
    if (currentIndex === 1)
      return (
        <h1
          className="step__title"
          dangerouslySetInnerHTML={{ __html: t("faq.Uploadyoursecond") }}
        ></h1>
      );
    if (currentIndex === 2)
      return (
        <h1
          className="step__title"
          dangerouslySetInnerHTML={{ __html: t("faq.Uploadyourthird") }}
        ></h1>
      );
  };

  const handleDrop = (files: any) => {
    onChangeFileUrl(files[0]);
  };

  return !hidden ? (
    <div className="step step--art">
      <p className="step__name">{t("formRequestNewDesign.step")} 3</p>
      {renderTitle()}

      <div className="line">
        <div></div>
      </div>

      <div className="upload-artworks">
        <div className="upload-artworks_content">
          <Upload
            hidden
            id="uploadArtworksFile"
            className="upload"
            acceptedFiles={(files) => {
              handleAcceptFiles(files);
            }}
            accept="image/png, image/jpg, image/jpeg"
            children={<Add />}
          />

          <DragAndDrop handleDrop={handleDrop}>
            <div
              className="upload"
              onClick={() => {
                if (firstChoosedArtwork) {
                  setOpenInfoModal(true);
                  setFirstChoosedArtwork(false);
                  return;
                }
                document.getElementById("uploadArtworksFile")?.click();
              }}
            >
              <div>
                <Add />
              </div>
            </div>
          </DragAndDrop>

          <div className="images">
            <div className="images__item">
              <div>
                {" "}
                {artworksFilesReview.length > 0 && (
                  <img src={artworksFilesReview[0]} alt="artwork1" />
                )}
              </div>
            </div>
            <div className="images__item">
              <div>
                {artworksFilesReview.length > 1 && (
                  <img src={artworksFilesReview[1]} alt="artwork2" />
                )}
              </div>
            </div>
          </div>
          {!!artworksFiles.length && (
            <div
              className="skip"
              onClick={() => {
                handleSetArtworksInfo(artworksFiles, artworksInfos);
              }}
            >
              {t("faq.Skiptonextstep")}
            </div>
          )}
        </div>
      </div>

      <div className="step__description">
        <div>
          <p>
            {t("faq.Clickboxabove").replace(
              "{0}",
              currentIndex === 0
                ? t("first")
                : currentIndex === 1
                ? t("second")
                : t("third")
            )}
          </p>
          <p>
            {t("faq.Pickartworksyou").replace(
              "{0}",
              currentIndex === 0
                ? t("first")
                : currentIndex === 1
                ? t("second")
                : t("third")
            )}
          </p>
          <p>{t("faq.Anddonntworry")}</p>
        </div>
      </div>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="line line--commitment">
          <div></div>
        </div>
      </Box>

      <ModalUploadFileErr open={openErrModal} t={t} />
      <ModalChooseInfoArtwork
        t={t}
        open={open}
        artworksFileUrl={
          !!artworksFilesReview[currentIndex]
            ? artworksFilesReview[currentIndex]
            : ""
        }
        handleUploadArtworks={handleUploadArtworks}
        currentIndex={currentIndex}
      />

      <InfoModal
        open={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
        }}
      />
    </div>
  ) : null;
}

interface IModalErr {
  open: boolean;
  t: any;
}

const ModalUploadFileErr = (props: IModalErr) => {
  const { open, t } = props;

  return (
    <div className={className("popup-upload-file-err", open && "show")}>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <div className="top">
          <div className="opacity"></div>
          <div className="content">
            <div>
              <h1
                className="step__title"
                dangerouslySetInnerHTML={{
                  __html: t("ModalUploadFileErr.Sorry"),
                }}
              ></h1>

              <button
                className="btn-upload-file"
                onClick={() => {
                  document.getElementById("uploadArtworksFile")?.click();
                }}
              >
                {t("ModalUploadFileErr.upload")}
              </button>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div>{t("ModalUploadFileErr.Yourimage")}</div>
        </div>
      </Box>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <div className="top">
          <div className="opacity"></div>
          <div className="content">
            <div>
              <div>
                <h1
                  className="step__title"
                  dangerouslySetInnerHTML={{
                    __html: t("ModalUploadFileErr.Sorry"),
                  }}
                ></h1>

                <button
                  className="btn-upload-file"
                  onClick={() => {
                    document.getElementById("uploadArtworksFile")?.click();
                  }}
                >
                  {t("ModalUploadFileErr.upload")}
                </button>
              </div>

              <div className="line line-desktop">
                <div></div>
              </div>
              <div>{t("ModalUploadFileErr.Yourimage")}</div>
            </div>
          </div>
          <div className="bottom"></div>
        </div>
      </Box>
    </div>
  );
};

enum ArtworkStep {
  Name,
  Technique,
  Style,
}

interface IModalChooseInfoArtwork extends IModalErr {
  artworksFileUrl: string;
  handleUploadArtworks: (artworksInfos: IArtworksInfos) => void;
  currentIndex: number;
}
const ModalChooseInfoArtwork = (props: IModalChooseInfoArtwork) => {
  const { open, artworksFileUrl, handleUploadArtworks, currentIndex } = props;
  const { t } = useTranslation();

  const [step, setStep] = useState<ArtworkStep>(ArtworkStep.Name);
  const [techniques, setTechniques] = useState<ITechniqueRes[]>([]);
  const [styles, setStyles] = useState<IStyleRes[]>([]);
  const [data, setdata] = useState<IArtworksInfos>({ ...initData });

  useEffect(() => {
    setTechniques(getTechniques());
    setStyles(getStyles());
  }, []);

  const handleSubmitName = () => {
    if (!data.name) return;

    setStep(ArtworkStep.Technique);
  };

  const renderStepName = () => {
    return (
      <>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <div className="line">
            <div></div>
          </div>
        </Box>
        <div className="group-enter-name">
          <div>
            <input
              id="inputNameArtwork"
              type="text"
              placeholder={t("EnterArtworkName")}
              value={data.name}
              onChange={(e) => {
                setdata((pre) => {
                  return { ...pre, name: e.target.value };
                });
              }}
              onKeyPress={(e) => {
                if (e.key.toLowerCase() === "enter") handleSubmitName();
              }}
            />
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <button onClick={handleSubmitName} disabled={!data.name}>
                <Done />
              </button>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <button onClick={handleSubmitName} disabled={!data.name}>
                {t("formRequestNewDesign.modalUploadArtwork.submit")}
              </button>
            </Box>
          </div>
        </div>
      </>
    );
  };
  const renderStepTechnique = () => {
    return (
      <>
        <div className="line">
          <div></div>
        </div>
        <div className="box">
          <div>
            {techniques.map((item) => {
              const Icon = item.icon;

              return (
                <div
                  className="item"
                  key={item.id}
                  onClick={() => {
                    setdata((pre) => {
                      return { ...pre, technique: item.id };
                    });
                    setStep(ArtworkStep.Style);
                  }}
                >
                  <div className="div-img">
                    <Icon
                      style={item.name === "Writing" ? { marginLeft: 6 } : {}}
                    />
                  </div>
                  <div className="text">{t(item.name)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  const renderStepStyle = () => {
    return (
      <>
        <div className="line">
          <div></div>
        </div>
        <div className="box box--style">
          <div>
            {styles.map((item) => {
              const Icon = item.icon;

              return (
                <div
                  className="item"
                  key={item.id}
                  onClick={() => {
                    handleUploadArtworks({ ...data, style: item.id });
                    setdata({ ...initData });
                    setStep(ArtworkStep.Name);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="div-img">
                    <Icon />
                  </div>
                  <div className="text">{t(item.name)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderText = () => {
    return currentIndex === 0
      ? t("first")
      : currentIndex === 1
      ? t("second")
      : t("third");
  };

  const renderTitleStep = () => {
    if (step === ArtworkStep.Name) {
      return (
        <h1 className="step__title">
          {t("formRequestNewDesign.modalUploadArtwork.nameYour")}
          <br />
          {renderText()} {t("formRequestNewDesign.modalUploadArtwork.artwork")}
        </h1>
      );
    }
    if (step === ArtworkStep.Technique) {
      return (
        <h1 className="step__title">
          {t("formRequestNewDesign.modalUploadArtwork.selectYour")}
          <br />
          {t("formRequestNewDesign.modalUploadArtwork.technique")}
        </h1>
      );
    }
    if (step === ArtworkStep.Style) {
      return (
        <h1 className="step__title">
          {t("formRequestNewDesign.modalUploadArtwork.selectYour")}
          <br />
          {t("formRequestNewDesign.modalUploadArtwork.Style")}
        </h1>
      );
    }
  };

  const renderContent = () => {
    return (
      <div>
        <p className="step__name">{`${step + 1}/3`}</p>
        {renderTitleStep()}

        {step === ArtworkStep.Name && renderStepName()}
        {step === ArtworkStep.Technique && renderStepTechnique()}
        {step === ArtworkStep.Style && renderStepStyle()}

        <div className="group-images">
          <img src={artworksFileUrl} alt="artwork" />
          {data.name && step !== ArtworkStep.Name && (
            <div className="artwork-name">{data.name}</div>
          )}

          {step === ArtworkStep.Name && (
            <div
              className="text"
              onClick={() => {
                document.getElementById("uploadArtworksFile")?.click();
              }}
            >
              {t("formRequestNewDesign.modalUploadArtwork.changeFile")}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={className("popup-upload-choose-info-artwork", open && "show")}
    >
      <div className="opacity"></div>
      <div className="content">{renderContent()}</div>
      <div className="bottom"></div>
    </div>
  );
};

interface InfoModalType {
  open: boolean;
  onClose: () => void;
}

const InfoModal = (props: InfoModalType) => {
  const { open, onClose } = props;
  const { t } = useTranslation();

  return (
    <div
      className={className(
        "popup-upload-choose-info-artwork info-modal",
        open && "show"
      )}
    >
      <div className="opacity"></div>
      <div className="content">
        <div className="content-info-modal">
          <div>
            <h1 className="step__title step__title--info">
              {t(
                "formRequestNewDesign.infoModal.forGreatProductsSelectAGreatPictureOfYourArt"
              )}
            </h1>

            <div className="group-image-sharp">
              <div className="group-image-sharp__image">
                <img src={APlus} className="image-1" alt="a plus" />
                <img
                  src={PictureFrame}
                  className="image-2"
                  alt="image is sharp"
                />
              </div>
              <div className="group-image-sharp__text">
                {t("formRequestNewDesign.infoModal.imageIsSharp")}
                <br />
                {t(
                  "formRequestNewDesign.infoModal.cameraIsDirectlyInFrontOfTheArtwork"
                )}
                <br />
                {t("formRequestNewDesign.infoModal.noVisibleFlashes")}
                <br />
                {t(
                  "formRequestNewDesign.infoModal.artworkIsCloselyCroppedoutOfContext"
                )}
              </div>
            </div>

            <div className="group-image-not-use">
              <div className="group-image-not-use__item">
                <div className="group-image-not-use__item__image">
                  <img src={Crossed} className="image-1" alt="Crossed" />
                  <img src={Blurry} className="image-2" alt="Blurry" />
                </div>
                <div className="group-image-not-use__item__text">
                  {t("formRequestNewDesign.infoModal.notBlurred")}
                </div>
              </div>
              <div className="group-image-not-use__item">
                <div className="group-image-not-use__item__image">
                  <img src={Crossed} className="image-1" alt="Crossed" />
                  <img src={Angle} className="image-2" alt="Angle" />
                </div>
                <div className="group-image-not-use__item__text">
                  {t("formRequestNewDesign.infoModal.noAngle")}
                </div>
              </div>
              <div className="group-image-not-use__item">
                <div className="group-image-not-use__item__image">
                  <img src={Crossed} className="image-1" alt="Crossed" />
                  <img src={Flash} className="image-2" alt="Flash" />
                </div>
                <div className="group-image-not-use__item__text">
                  {t("formRequestNewDesign.infoModal.noFlash")}
                </div>
              </div>
              <div className="group-image-not-use__item">
                <div className="group-image-not-use__item__image">
                  <img src={Crossed} className="image-1" alt="Crossed" />
                  <img src={NoContext} className="image-2" alt="No Context" />
                </div>
                <div className="group-image-not-use__item__text">
                  {t("formRequestNewDesign.infoModal.noContext")}
                </div>
              </div>
            </div>

            <div className="btn-i-understand">
              <button
                onClick={() => {
                  onClose();
                  document.getElementById("uploadArtworksFile")?.click();
                }}
              >
                {t("formRequestNewDesign.infoModal.iUnderstand")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom"></div>
    </div>
  );
};
