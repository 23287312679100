import { getLanguage } from "./../helpers/storage"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import enJson from "./en.json"
import frJson from "./fr.json"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		interpolation: { escapeValue: false },
		lng: getLanguage() || "en",
		resources: {
			en: { common: enJson },
			fr: { common: frJson },
		},
		// debug: true,
		react: {
			wait: true,
		},
		ns: "common",
		defaultNS: "common",
	})

export const changeLanguage = (language: string) => {
	i18n.changeLanguage(language)
}

export const getCurrentLang = () => i18n.language || "en"

export default i18n
